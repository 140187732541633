import "./App.css";


import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Edreamz from "./Components/Edreamzz";
import Header from "./Components/Header";
import Blogs from "./Components/Blogs";
import BlogDetails from "./Components/BlogDetails";
import Category from "./Components/Category";
import CommentSection from "./Components/CommentSection";
import GoogleLogin from './Components/GoogleLogin';
function App() {
  return (
    <>
      <Header/>
      <Routes>
    
        <Route path="/" element={<Blogs />} />
        <Route path="/google-login" element={<GoogleLogin />} />
        <Route path="comments" element={<CommentSection />} />
         <Route path="/blog/:id" element={<BlogDetails />}>
          <Route path="comments" element={<CommentSection />} />
        </Route>
        <Route path="/" element={<Edreamz />} />
        <Route path="/blogs" element={<Category/>} />
      </Routes>
    </>
  );
}

export default App;
