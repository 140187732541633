import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

function HeaderDisplay() {
  const [combinedData, setCombinedData] = useState([]);

  useEffect(() => {
    // Fetch data from the 'header-data' endpoint
    axios.get('https://blog-backend-ivfv.onrender.com/header-data')
      .then(response => {
        const headerDataWithType = response.data.map(d => ({ ...d, type: 'header' }));
        
        setCombinedData(prevData => {
          const newHeaderData = headerDataWithType.filter(newHeader => !prevData.some(existingData => existingData.title === newHeader.title));
          return [...prevData, ...newHeaderData];
        });
      })
      .catch(error => {
        console.error('Error fetching header data:', error);
      });
  }, []);

  return (
    <div className='navbar'>
      <Container>
        <ul className="links">
          {combinedData.map(data => (
            <li key={data.title}>
              {data.type === 'header' && (
  <>
    {data.image && <a href={"https://bestowalsystems.com/"}><img src={data.image} style={{ height: '80px', width: '', }} alt="Header Icon" /> </a>}
    {/* {data.links && data.links.map(link => (
      <Link key={link.title} to={link.url}>{link.title}</Link>
    ))} */}
                </>
              )}
              {/* {data.type === 'page' && (
                <Link to={`/page/${data.title}`}>{data.title}</Link>
              )} */}
            </li>
            
          ))}
 
        </ul>

        <span className='headtitle'> Blogs </span>

      </Container>
    </div>
  );
}

export default HeaderDisplay;
