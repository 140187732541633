import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
const Category = () => {
  const [originalProductData, setOriginalProductData] = useState([]);
  const [filteredProductData, setFilteredProductData] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  
  useEffect(() => {
  fetch('https://blog-backend-ivfv.onrender.com/approved-blogs') // Updated endpoint
    .then((res) => res.json())
    .then((jsonRes) => {
      setOriginalProductData(jsonRes);
      setFilteredProductData(jsonRes);

      const uniqueCategories = [...new Set(jsonRes.map((item) => item.category))];
      setUniqueCategories(uniqueCategories);
    })
    .catch((error) => {
      console.error('Error fetching approved blogs:', error);
      // Handle error if needed
    });
}, []);


  const filterCategory = (catItem) => {
     if (catItem === 'All') {
      setFilteredProductData(originalProductData);
    } else {
      const result = originalProductData.filter((curData) => curData.category === catItem);
      setFilteredProductData(result);
    }
    setSearchTerm(''); // Clear search term when filtering by category
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterByName = () => {
    const result = originalProductData.filter(
      (curData) =>
        curData.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        curData.summary.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProductData(result);
  };

  return (
    <div className='container-fluid blogs'>

      <div className='container px-4'>
        <div className='row gx-4"' >
        <div className='allserchbox'>
          <h1> Blogs List</h1>
              <input
                className=''
                type='text'
                placeholder='Search by name'
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <button className='btn searchbtn btn-primary ml-2 mb-2' onClick={filterByName}>
                Search
              </button>
            </div>
          <div className='col-md-9 leftsec'>
            <div className='row leftsecinner' >
              {filteredProductData.map((blog, id) => (
                <div className='col-md-12 mb-3' key={id}>
                  <div className="blagsec">
                    <Link to={`/blog/${blog._id}`} className="blagsec_title" >{blog.title}</Link>
                    <div className="blagsecinner">
                      <p className="blagsecinnertext">{blog.summary}</p>
                      <span>Author: <span className='autherinner'> {blog.author && blog.author.name ? blog.author.name : "BCS"} </span> </span> <span> Posted in : {blog.category}  </span> <span> {moment(blog.createdAt).format('MMMM Do YYYY, h:mm a')}   </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='col-md-3 rightsec' >
          
          <h4> Select Category </h4>
            <button className='w-100 mb-2 detailsbtn' onClick={() => filterCategory('All')}>
              All
            </button>
            {uniqueCategories.map((category, index) => {
              const categoryLength = originalProductData.filter((curData) => curData.category === category).length;
              return (
                <button
                  className='w-100 mb-2 detailsbtn'
                  key={index}
                  onClick={() => filterCategory(category)}
                >
                  {category} ({categoryLength})
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
