
import React from 'react'
import Container from 'react-bootstrap/Container';

// eslint-disable-next-line no-unused-vars
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const Edreamzz = () => {
  
  return (
    <>
<Container class="panel-section">
  <section className="panel-msg">
  <Row>
  <Col md={6}>
<h1 ><strong>Food Devlivery App</strong></h1>
<p className='mt-5'>Get an instant audit of your account with the<br></br> Free Google Ads Performance Grader</p>
<button >Read More</button> 
</Col>

</Row>
</section> 
 </Container>
 </>
  )
}

export default Edreamzz