import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import CommentSection from "./CommentSection";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";
import Container from "react-bootstrap/esm/Container";
import '../App.css'

function BlogDetails() {
  const [blog, setBlog] = useState(null);
  const { id } = useParams();
  const [comments, setComments] = useState([]);
  const [commentsLoaded, setCommentsLoaded] = useState(false);
  let profilePicURL = null;

  useEffect(() => {
    axios
      .get(`https://blog-backend-ivfv.onrender.com/blogs/${id}`)
      .then((response) => {
        setBlog(response.data);
      })
      .catch((error) => {
        console.error("Error fetching blog details:", error);
      });
  }, [id]);

  useEffect(() => {
    // Fetch comments
    axios
      .get(`https://blog-backend-ivfv.onrender.com/comments/${id}`)
      .then((response) => {
        setComments(response.data);
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
      });
  }, [id]);

  const loadComments = () => {
    setCommentsLoaded(true);
  };

  if (!blog) {
    return <div>Loading...</div>;
  }

  if (blog.author && blog.author.profilePic) {
    profilePicURL = `https://blog-backend-ivfv.onrender.com/${blog.author.profilePic}`;
  } else {
    console.log("Author details not available");
    // or display a message on the UI, e.g., setting an HTML element innerHTML property
  }

  return (
    <>
      <div className="container-fluid blogsinner">


   <div className="container">
      <div className="backbtn">
          <Link to="/blogs"> Back </Link>
        </div>

        <h2> Blog Detail</h2>
        </div>

      <div className="container blogssec">
       
        <div className="blogdetails">
          {blog.author && (
            <>
 <div className="aythername"> 
            <div className="lft">   {profilePicURL && (
                <img src={profilePicURL} alt="Author Profile" onError={(e) => console.error('Error loading profile picture:', e)} style={{ width: '80px', height: '80px' }} />
              )}</div>

              <div className="rightsec">

              <h2>Author : {blog.author.name || "BCS"}</h2>
             
              <h4>{blog.author.description || ""}</h4>

              </div>
              </div>
            </>
          )}
          <h3>{blog.title}</h3>
          <p>{blog.summary}</p>
          {blog.image && (
            <img
              src={`data:image/jpeg;base64,${blog.image}`}
              alt="Blog"
            />
          )}
          <div className="ql-editor">
            <div dangerouslySetInnerHTML={{ __html: blog.content }} />
          </div>
        </div>

        {/* Render Comment Section with comments */}
      
      </div>

      <Container>

      <div>
          {comments.map((comment) => (
            <div key={comment.id} className="comment">
              <p>{comment.text}</p>
              <p>Posted by: {comment.user}</p>
            </div>
          ))}
        </div>
        {/* Render "Load Comments" button if comments are not yet loaded */}
        {!commentsLoaded && (
          <button className="btncolor" onClick={loadComments}>Comments</button>
        )}

<div>

        {/* Render CommentSection if comments are loaded */}
        {commentsLoaded && <CommentSection blogId={id} />}
        </div>

      </Container>
      <Container>
        <div className="share-buttons mb-3 ">
          <FacebookShareButton url={window.location.href} quote={blog.title}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <TwitterShareButton url={window.location.href} title={blog.title}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <LinkedinShareButton url={window.location.href} title={blog.title} summary={blog.summary}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
      </Container>
      </div>
    </>
  );
}

export default BlogDetails;
