// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import Category from './Category';


// function Blogs() {
//   const [blogs, setBlogs] = useState([]);

//   useEffect(() => {
//     axios.get('https://blog-backend-ivfv.onrender.com/blogs')
//       .then(response => {
//         setBlogs(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching blogs data:', error);
//       });
//   }, []);

//   // Extract unique categories from the list of blogs
//   const uniqueCategories = [...new Set(blogs.map(blog => blog.category))];

//   return (<>
//     <div>
//       <div>
//         <h2>All Blogs</h2>
//         <ul>
//           {blogs.map(blog => (
//             <li key={blog._id}>
//               <h3>{blog.title}</h3>
//               <p>{blog.summary}</p>
//               <p>Category: {blog.category}</p>
//               <Link to={`/blog/${blog._id}`}>Read More</Link>
//             </li>
//           ))}
//         </ul>
//       </div>
//       <div>
//         <h2>Categories</h2>
//         <ul>
//           {uniqueCategories.map(category => (
//             <li key={category}>
//               <Link to={`/category/${category}`}>{category}</Link>
//             </li>
//           ))}
//         </ul>
//       </div>
      
//     </div>
//     <Category/>
//     </>
//   );
// }

// export default Blogs;
import React from 'react'
import Category from './Category'

const Blogs = () => {
  return (
    <div>
      <Category/>
    </div>
  )
}

export default Blogs